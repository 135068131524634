import { NgModule } from '@angular/core';
import { RouterModule, Routes, PreloadAllModules } from '@angular/router';
import { AuthguardService } from './authorized/services/authguard.service';
import { LoginGuardService } from './authorized/services/login-guard.service';

const appRoutes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./authorized/authorized.module').then(
        (mod) => mod.AuthorizedModule
      ),
    canActivate: [AuthguardService],
  },
  {
    path: 'account/signin',
    loadChildren: () =>
      import('./authentication/authentication.module').then(
        (mod) => mod.AuthenticationModule
      ),
    canActivate: [LoginGuardService],
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes, {
      preloadingStrategy: PreloadAllModules,
      anchorScrolling: 'enabled',
      scrollPositionRestoration: 'enabled'
    })
  ],
  exports: [
    RouterModule
  ]
})

export class AppRoutingModule {
}