import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root',
})
export class PermissionService {

    constructor(private http: HttpClient) { }

    permissions(url: any): Observable<string[]> {

        let params = new HttpParams().set('url', url);

        return this.http.get<string[]>(
            `${environment.API_URL}/menu/permission`, { params }
        );
    }

}
