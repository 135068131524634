import { NzMessageService } from 'ng-zorro-antd/message';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { StorageService } from './storage.service';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SigninService {

  constructor(
    private storageService: StorageService,
    // private messageService: NzMessageService,
    private http: HttpClient) { }

  logout(): void {

    this.storageService.removeIdentity();
  }

  login(cridential: any) {
    return this.http
      .post(`${environment.API_URL}/signin`, cridential)
      .pipe(
        catchError((error: any) => {
          return throwError(error.error);
        })
      );
  }

  changePassword(data: any) {
    return this.http.put(`${environment.API_URL}/user/changePassword`, data);
  }

}
